<template>
  <div class="Home">
    <van-nav-bar title="ERP"></van-nav-bar>
    <div class="list-wrap">
      <div class="card-wrap">
        <div class="card-title">
          <span>我要审批</span>
        </div>
        <div class="card-content">
          <van-grid :column-num="3">
            <van-grid-item @click="handleTab('approvalRei')" :icon="reimbursementIcon" text="报销审批" />
            <van-grid-item @click="handleTab('withdrawal')" :icon="requestFundsIcon" text="请款审批" />
            <van-grid-item @click="handleTab('invoiceApprove')" :icon="invoiceIcon" text="发票审批" />
            <van-grid-item @click="handleTab('contractProgress')" :icon="contractIcon" text="合同审批" />
            <van-grid-item @click="handleTab()" :icon="insideTransferIcon" text="内部转账审批" />
            <van-grid-item @click="handleTab()" :icon="invoiceReceiptIcon" text="发票签收单审批" />
            <van-grid-item @click="handleTab()" :icon="noInvoiceReceiptIcon" text="发票签收单(无单)审批" />
            <van-grid-item @click="handleTab('companyManagement')" :icon="companyIcon" text="公司管理" />
            <van-grid-item @click="handleTab('otherApproval')" :icon="otherApprovalIcon" text="其他审批" />
          </van-grid>
        </div>
      </div>

      <div class="card-wrap">
        <div class="card-title">
          <span>到款信息</span>
        </div>
        <div class="card-content">
          <van-grid :column-num="3">
            <van-grid-item @click="handleTab('automatic')" :icon="receiveMoneyIcon" text="到款录入" />
          </van-grid>
        </div>
      </div>

      <div class="card-wrap">
        <div class="card-title">
          <span>我的信息</span>
        </div>
        <div class="card-content">
          <van-grid :column-num="3">
            <van-grid-item @click="handleTab()" :icon="userInfoIcon" text="基础信息" />
          </van-grid>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { NavBar, Icon, Grid, GridItem, Toast } from "vant";
Vue.use(NavBar).use(Icon).use(Grid).use(GridItem).use(Toast);
import underConstructionIcon from '@/assets/underConstructionIcon.png'
export default {
  name: "Home",
  data() {
    return {
      reimbursementIcon: require('@/assets/reimbursementIcon.png'),
      requestFundsIcon: require('@/assets/requestFundsIcon.png'),
      invoiceIcon: require('@/assets/invoiceIcon.png'),
      contractIcon: require('@/assets/contractIcon.png'),
      receiveMoneyIcon: require('@/assets/receiveMoneyIcon.png'),
      insideTransferIcon: require('@/assets/insideTransferIcon.png'),
      invoiceReceiptIcon: require('@/assets/invoiceReceiptIcon.png'),
      noInvoiceReceiptIcon: require('@/assets/noInvoiceReceiptIcon.png'),
      userInfoIcon: require('@/assets/userInfoIcon.png'),
      otherApprovalIcon: require('@/assets/otherApprovalIcon.png'),
      companyIcon: require('@/assets/company-approval-icon.png'),
    };
  },
  mounted() {

  },

  created() {},

  methods: {
    handleTab(url){
      if(url){
        this.$router.push(url);
      } else {
        Toast({
          message: '正在建设中',
          icon: underConstructionIcon,
        });
      }
    },
    handleAppWait() {
      this.$router.push("invoiceApprove")
    },
  },
};
</script>

<style lang="scss">
.Home {
  background: $back;
  .van-nav-bar{
    position: sticky;
    top: 0;
  }
  .list-wrap{
    overflow: auto;
    height: calc(100vh - 46px);
    .card-wrap{
      background-color: #fff;
      margin: 10px 8px;
      border-radius: 4px;
      .card-title{
        padding: 14px 20px;
        span{
          color: $gray;
        }
      }
      .card-content{
        .van-grid{
          .van-grid-item{
            .van-grid-item__content--center{
              text-align: center;
              .van-icon{
                img{
                  width: 0.4rem;
                  height: 0.4rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>